import * as React from "react"

import MainMenu from "../components/MainMenu/Mainmenu"
import SectionTen from "../components/SectionTen/SectionTen"
import Seo from "../components/seo"


const AerialFilming= () => (
    <>
    <Seo title="Aerial filming" />
    <MainMenu/>
  
    <SectionTen/>
    </>
)
export default AerialFilming